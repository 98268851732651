import React, { useEffect, useState, useContext } from "react";

import { myContext } from "../../utils/provider";

import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router"; // this helps tracking the location
import "./banner.css";
import LoadingSpinner from "./loading";
import bg from "./check.png";
import { Box } from "@mui/material";

const Subscribe = ({ postName, tag, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      imageBlackFriday: file(
        name: { regex: "/(black_friday_banner_desktop)/" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      imageBlackFridayMobile: file(
        name: { regex: "/(black_friday_banner_mobile)/" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      blockedCountries: allBlockedCountriesJson {
        nodes {
          country
          countryCode
        }
      }
    }
  `);

  const blockedCountries = data.blockedCountries.nodes;

  const location = useLocation();
  const context = useContext(myContext);

  const [isBlocked, setIsBlocked] = useState(null);

  const setBlockCountry = (countryCode) => {
    setIsBlocked(
      blockedCountries.some((item) => item.countryCode == countryCode)
    );
  };

  useEffect(() => {
    setBlockCountry(context.country);
  });

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [inputEmail, setInputEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const addToHubSpot = (inputEmail) => {
    setIsLoading(true);

    fetch(
      "https://us-central1-website-b24cd.cloudfunctions.net/addSubscriberCheckList",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: inputEmail,
        }),
      }
    )
      .then(async (res) => {
        const data = await res.json(); // Convert response to JSON

        if (!res.ok) {
          // If the response is NOT ok (e.g., 400 or 500 status)
          setSuccess("");
          throw new Error(data.error || "Something went wrong");
        }
        return data;
      })
      .then((data) => {
        setSuccess(data);
        setIsLoading(false);
        setError("");
      })
      .catch((error) => {
        console.error("Error:", error);
        setError(error.message || "An error occurred. Please try again.");
        setIsLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (inputEmail) {
      try {
        addToHubSpot(inputEmail);
      } catch {}
    }
  };
  return (
    <>
      {!context.country ? (
        <div></div>
      ) : !isBlocked ? (
        <div>
          <>
            <Box display={{ sm: "block", md: "block", xs: "none" }}>
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    display: "flex",
                    width: "50%",
                    padding: "20px",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    zIndex: "30",
                    marginTop: "5%",
                    paddingTop: "10%",
                    position: "relative",
                  }}
                >
                  <b style={{ fontSize: "20px" }}>LIVE SURGERY CHECK LIST</b>
                  <div style={{ marginBottom: "1em", marginTop: "1em" }}>
                    Join the ranks of top-tier medical professionals who are
                    harnessing the{" "}
                    <b>
                      power of live surgery streaming to educate, connect, and
                      inspire
                    </b>
                    . Download our comprehensive Live Surgery Streaming
                    Checklist today!
                  </div>{" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <form
                      onSubmit={handleSubmit}
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <input
                        type="text"
                        placeholder="Type your e-mail"
                        className="input_subscribe"
                        value={inputEmail}
                        onInput={(e) => setInputEmail(e.target.value)}
                        name="email"
                        required
                      ></input>
                      <button
                        type="submit"
                        className="shop"
                        style={{
                          borderRadius: "0",
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }}
                      >
                        DOWNLOAD NOW
                      </button>
                    </form>
                    <div style={{ textAlign: "center" }}>
                      {isLoading ? <LoadingSpinner /> : ""}

                      {success.status === "success" ? (
                        <div>
                          <h5
                            style={{
                              marginTop: "0.5em",
                              marginBottom: "0.5em",
                            }}
                          >
                            Thank you for subscribing!
                          </h5>
                          <div>
                            You will receive the checklist in your email
                            shortly.
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {success.status === "updated" ? (
                        <div>
                          <h5
                            style={{
                              marginTop: "0.5em",
                              marginBottom: "0.5em",
                            }}
                          >
                            Thank you for subscribing!
                          </h5>
                          <div>
                            You will receive the checklist in your email
                            shortly.
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <b>{error}</b>
                    </div>
                  </div>{" "}
                  <p style={{ fontSize: "13px" }}>
                    *By downloading this check list, you will also accept to
                    receive sector news, recording tips, exclusive discounts,
                    and more via email.
                  </p>
                </div>
                <div style={{ width: "100%" }}>
                  <img
                    src={bg}
                    style={{
                      maxWidth: "100%",
                      position: "absolute",
                      top: 0,
                    }}
                  ></img>
                </div>
              </div>
            </Box>
            <Box display={{ sm: "none", md: "none", xs: "block" }}>
              <div
                style={{
                  margin: "0",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "60px",
                    position: "absolute",
                    backgroundColor: "#2EC4B6",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                    style={{ width: "100%", height: "190px" }}
                  >
                    <path
                      fill="#2EC4B6"
                      fill-opacity="1"
                      d="M0,128L48,144C96,160,192,192,288,186.7C384,181,480,139,576,112C672,85,768,75,864,90.7C960,107,1056,149,1152,160C1248,171,1344,149,1392,138.7L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
                    ></path>
                  </svg>
                </div>
                <div
                  style={{
                    zIndex: "3",
                    position: "relative",
                    padding: "15px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      textAlign: "center",
                      fontWeight: "600",
                      padding: "0px 10px",
                      width: "96%",
                      marginBottom: "1em",
                      color: "white",
                    }}
                  >
                    LIVE SURGERY CHECK LIST
                  </div>
                  <div style={{ marginBottom: "1em" }}>
                    Join the ranks of top-tier medical professionals who are
                    harnessing the{" "}
                    <b>
                      power of live surgery streaming to educate, connect, and
                      inspire
                    </b>
                    . Download our comprehensive Live Surgery Streaming
                    Checklist today!
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <form
                      onSubmit={handleSubmit}
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <input
                        type="text"
                        placeholder="Type your e-mail"
                        className="input_subscribe"
                        value={inputEmail}
                        onInput={(e) => setInputEmail(e.target.value)}
                        name="email"
                        required
                      ></input>
                      <button
                        type="submit"
                        className="shop"
                        style={{
                          borderRadius: "0",
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }}
                      >
                        Get now
                      </button>
                    </form>
                    <div style={{ textAlign: "center" }}>
                      {isLoading ? <LoadingSpinner /> : ""}
                      {success.status === "success" ? (
                        <div>
                          {" "}
                          <h1>Thank you for subscribing!</h1>
                          <div>
                            You will receive the check list in your email in
                            some seconds.
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {success.status === "updated" ? (
                        <div>
                          {" "}
                          <h1>Thank you for subscribing!</h1>
                          <div>
                            You will soon receive the check list in your email.
                          </div>
                        </div>
                      ) : (
                        ""
                      )}{" "}
                      <b>{error}</b>
                    </div>
                  </div>
                  <p style={{ fontSize: "13px", marginTop: "1em" }}>
                    *By downloading this check list, you will also accept to
                    receive sector news, recording tips, exclusive discounts,
                    and more via email.
                  </p>{" "}
                </div>
              </div>
            </Box>
          </>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Subscribe;
